@import '../../assets/scss/core';

header {
  box-shadow: 0 5px 20px c(dark, 0.3);
  padding: rem(20) rem(25);
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 400ms;
  background: url('../../assets/images/headerBg.png') repeat-x center center / 200px;
  color: c(dark);
  @include mobile {
    padding: rem(15) rem(25);
  }
  @include not-mobile {
    &.small {
      padding: rem(15) rem(25);
      .header-block {
        .logo {
          > img {
            height: rem(40);
          }
        }
      }
    }
  }
  .header-block {
    max-width: var(--max-width);
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      position: relative;
      > img {
        height: rem(70);
        transition: all 300ms;
        @include mobile {
          height: rem(50);
        }
      }
    }
    .icon-menu {
      font-size: rem(22);
      background: c(dark-gray, 0.3);
      color: c(dark, 0.9);
      padding: rem(10);
      width: rem(40);
      height: rem(40);
      display: none;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      @include mobile {
        display: flex;
      }
      &.opened {
        &:before {
          content: '\e902';
        }
      }
      @include hover {
        background: c(dark-gray, 0.8);
      }
    }
    .navigation {
      display: inline-grid;
      gap: rem(15);
      grid-auto-flow: column;
      align-items: center;
      font-size: 15px;
      @include mobile {
        position: fixed;
        background: c(light);
        padding: rem(10);
        width: 100%;
        height: calc(100% - 64px);
        border-top: 2px solid c(dark, 0.05);
        top: 64px;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow-y: auto;
        grid-auto-flow: unset;
        gap: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(100%);
        transition: all 300ms;
        &.opened {
          opacity: 1;
          visibility: visible;
          transform: translateX(0%);
        }
      }
      > li {
        border-radius: rem(5);
        height: rem(40);
        transition: all 200ms;
        background: c(light, 0.5);
        @include mobile {
          width: 100%;
          height: auto;
          font-size: rem(20);
          &.withSub {
            &.opened {
              > span {
                position: relative;
                &:before {
                  color: c(dark, 0.7);
                  transform: translateY(-50%) rotateX(180deg);
                }
              }
            }
            > span {
              position: relative;
              &:before {
                color: c(dark, 0.4);
                content: '\e900';
                font-family: 'almiwelt';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                transition: all 300ms;
              }
            }
          }
        }
        span {
          white-space: nowrap;
        }
      }
      &,
      a {
        color: c(dark, 0.5);
      }
      li {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: rem(10);
        cursor: pointer;
        &.active {
          font-weight: bold;
          pointer-events: none;
          cursor: default;
        }
        @include mobile {
          &:not(.opened):not(.language) {
            .submenu {
              max-height: 0;
            }
          }
          .submenu {
            visibility: visible;
            opacity: 1;
            pointer-events: auto;
            max-height: rem(300);
            transition: all 400ms linear;
            overflow: hidden;
          }
        }
        @include not-mobile {
          @include hover {
            background: c(ligh-gray);
            &,
            a {
              color: c(dark, 0.8);
            }
            .submenu {
              visibility: visible;
              opacity: 1;
              pointer-events: auto;
            }
          }
        }
      }
      .submenu {
        position: absolute;
        display: inline-flex;
        top: rem(15);
        right: 0;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        padding: rem(10) 0 0;
        flex-direction: column;
        margin: rem(20) 0 0;
        font-size: 15px;
        transition: all 200ms;
        @include mobile {
          position: static;
          margin: 0;
        }
        .submenu-content {
          background: c(ligh-gray);
          color: c(dark, 0.5);
          gap: rem(3);
          align-items: flex-start;
          padding: rem(10);
          border-radius: rem(5);
          display: inline-grid;
          box-shadow: 0 10px 20px 0 c(dark, 0.5);
          @include mobile {
            grid-auto-flow: unset;
            box-shadow: none;
            width: 100%;
          }
          &,
          > a,
          > li {
            transition: all 300ms;
          }
          > a,
          > li {
            width: 100%;
            display: inline-flex;
            flex-direction: column;
            border-radius: rem(5);
            color: c(dark, 0.6);
            padding: rem(6) rem(10);
            min-width: rem(150);
            @include hover {
              background: c(dark-gray);
              color: c(dark);
            }
          }
          .submenu {
            position: static;
            font-size: 13px;
            min-width: rem(200);
            margin: 0;
            .submenu-content {
              display: block;
              box-shadow: none;
            }
          }
        }
      }
    }
    .language {
      position: relative;
      @include mobile {
        margin: rem(20) 0 0;
        & > .submenu {
          padding: 0;
        }
      }
      .current {
        @include mobile {
          display: none;
        }
        .flag {
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          width: 20px;
          height: 20px;
          display: block;
          border-radius: 2px;
          &.de {
            background-image: url('../../assets/images/flags/deu.svg');
          }
          &.en {
            background-image: url('../../assets/images/flags/usa.svg');
          }
        }
      }
    }
  }
}
