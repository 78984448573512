@import '../../assets/scss/core';

main,
.contact-us-parent,
footer {
  max-width: var(--max-width);
  width: 100%;
  margin: 0 auto;
}

.content-block {
  margin-top: rem(106);
  position: relative;
  z-index: 5;
  background: c(ligh-gray);
  padding: rem(60) rem(30) rem(30);
  width: 100%;
  @include mobile {
    padding: rem(30) rem(20);
    margin-top: rem(76);
  }
}
section {
  display: flex;
  flex-direction: column;
  margin: rem(15) 0;
  & ~ section {
    margin: rem(60) 0 rem(15);
  }
  .development-img {
    border-radius: rem(6);
    margin: rem(15) 0 rem(25);
  }
  > h3,
  > h1 {
    display: inline-block;
    margin: 0 auto rem(30);
    text-align: center;
    color: c(primary);
    border-bottom: 1px solid c(primary);
    padding: 0 rem(10) rem(5);
    text-transform: uppercase;
  }
  > h1 {
    font-size: rem(30);
  }
  > p {
    font-size: rem(17);
    line-height: rem(26);
    & + p {
      margin: rem(20) 0 0;
    }
  }
}
.items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: rem(30);
  @include tablet {
    grid-template-columns: 1fr 1fr;
  }
  @include mobile {
    grid-template-columns: 1fr;
  }
  .item {
    position: relative;
    padding: rem(10);
    text-align: center;
    transition: opacity 300ms;
    margin: rem(12) rem(20);
    @include hover {
      opacity: 0.7;
    }
    @include mobile {
      &:not(:first-child) {
        border-top: 1px solid c(dark, 0.05);
        padding: rem(50) rem(10) rem(10);
        margin: 0 rem(20) rem(12);
      }
    }
    > img {
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 5px;
      margin: 0 auto;
      width: auto !important;
      height: rem(250);
    }
    > p {
      margin: rem(10) 0 0;
      font-size: 14px;
    }
    .invisibleLink {
      font-size: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
#root {
  display: grid;
}
