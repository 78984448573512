@import '../../assets/scss/core';

footer {
  background: c(light);
  border-radius: rem(5);
  padding: rem(20);
  .footer-logo {
    height: rem(80);
    margin: 0 auto;
  }
  .copyright {
    border-top: 1px solid c(dark, 0.1);
    padding: rem(10) 0 0;
  }
  .blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: rem(40) 0 rem(20);
    gap: rem(40);
    align-items: flex-start;
    @include mobile {
      grid-template-columns: 1fr;
    }
    .title {
      font-size: rem(22);
      color: c(primary);
      text-transform: uppercase;
      border-bottom: 1px solid c(primary);
      padding: rem(5) 0;
    }
    .info {
      color: c(secondary);
      line-height: 1.6;
      > p {
        &:not(:first-child) {
          margin: rem(10) 0 0;
        }
      }
    }
    .column {
      display: inline-grid;
      width: 100%;
      gap: rem(8);
      text-align: center;
      align-items: center;
      height: 100%;
    }
  }
}
