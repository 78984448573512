@import '../../assets/scss/core';

.contact-us-parent {
  border-top: 1px solid c(dark, 0.1);
  margin: rem(40) auto 0;
  padding: rem(30) 0;
  width: 100%;
  > h2,
  > p {
    text-align: center;
  }
  > p {
    margin: rem(10) 0 rem(30);
    color: c(dark, 0.6);
  }
  > form {
    display: grid;
    gap: rem(15);
    > button {
      justify-self: flex-end;
    }
  }
  .two-input {
    display: inline-grid;
    width: 100%;
    gap: rem(25);
    grid-template-columns: 1fr 1fr;
    @include mobile {
      grid-template-columns: 1fr;
    }
  }
}
