@import 'core';
@import 'reset';
@import 'inputs';
@import '../icons/style.css';

:root {
  --font: 'Open Sans', sans-serif;
  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }
  --shadow: rgba(0, 0, 0, 0.1);
  --max-width: 1100px;
}
html {
  font: 10px / 1.2 var(--font);
  @include tablet {
    font-size: 8px;
  }
}
#root {
  position: relative;
}

body {
  font-size: rem(16);
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  color: c(dark);
  background: c(light);
}
.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: rem(20);

  > i {
    font-size: rem(110);
  }
}

.skeleton {
  background: c(dark, 0.07);
  height: 14px;
  border-radius: 10px;
  animation: shine-lines 1s infinite ease-in-out alternate-reverse;
}

.input-skeleton {
  border: 1px solid c(dark, 0.05);
  border-radius: 5px;
  min-height: 48px;
}

@keyframes shine-lines {
  0% {
    background: c(dark, 0.07);
  }
  100% {
    background: c(dark, 0.02);
  }
}
