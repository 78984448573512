$colors: (
  primary: #a30100,
  primary-sc: #ffffff,
  secondary: #3f4a78,
  secondary-sc: #ffffff,
  danger: #c0392b,
  success: #27ae60,
  warning: #f1c40f,
  dark: #0d0d0d,
  light: #ffffff,
  ligh-gray: #f7f7f7,
  dark-gray: #e0e0e0,
);

@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
    @content;
  }
}
@mixin not-retina {
  @media (-webkit-max-device-pixel-ratio: 1) and (max-resolution: 191dpi) {
    @content;
  }
}
@function c($color, $opacity: 1) {
  @if $opacity == 1 {
    @return var(--#{$color});
  }
  @return rgba(var(--#{$color}-rgb), $opacity);
}
@mixin placeholder {
  $prefixList: '::-webkit-input', ':-moz', '::-moz', ':-ms-input';
  @each $prefix in $prefixList {
    &#{$prefix}-placeholder {
      @content;
      opacity: 1;
    }
  }
}
@function rem($size) {
  $remSize: $size / 10;
  @if $size == 1 {
    @return #{$size}px;
  }
  @return #{$remSize}rem;
}
@function -rem($size) {
  $remSize: $size / 10;
  @if $size == 1 {
    @return -#{$size}px;
  }
  @return -#{$remSize}rem;
}
@each $name, $color in $colors {
  $colors: map-merge($colors, (#{$name + '-rgb'}: #{red($color), green($color), blue($color)}));
  $colors: map-merge($colors, (#{$name + '-hover'}: darken($color, 5)));
}
@mixin scroll-y($scroll: auto) {
  $border-radius: 10px;
  $width: 4px;
  $scroll-main-color: rgba(var(--dark-rgb), 0.2);
  $scroll-main-thumb-color: rgba(var(--dark-rgb), 0.5);

  overflow-y: $scroll;
  overflow-x: hidden;
  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar {
    width: $width;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}
@mixin scroll-x($scroll: auto) {
  $border-radius: 10px;
  $height: 5px;
  $scroll-main-color: rgba(var(--dark-rgb), 0.2);
  $scroll-main-thumb-color: rgba(var(--dark-rgb), 0.5);
  overflow-y: hidden;
  overflow-x: $scroll;
  scrollbar-track-color: $scroll-main-color;
  scrollbar-shadow-color: $scroll-main-color;
  scrollbar-base-color: $scroll-main-thumb-color;
  scrollbar-arrow-color: $scroll-main-thumb-color;
  &::-webkit-scrollbar {
    height: $height;
    background: $scroll-main-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $scroll-main-thumb-color;
  }
}
@mixin font($font-name, $weight: normal) {
  $path: '../fonts/' + $font-name + '/' + $font-name;
  @font-face {
    font-family: quote($font-name);
    src: url('#{$path}.eot');
    src: url('#{$path}.eot?#iefix') format('embedded-opentype'), url('#{$path}.woff') format('woff'),
      url('#{$path}.woff2') format('woff2'), url('#{$path}.ttf') format('truetype'),
      url('#{$path}.svg##{$font-name}') format('svg');
    font-weight: $weight;
    font-style: normal;
    font-display: swap;
  }
}
@mixin not-desktop {
  @media screen and (max-width: 850px) {
    @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 580px) {
    @content;
  }
}
@mixin not-mobile {
  @media screen and (min-width: 581px) {
    @content;
  }
}
@mixin not-tablet {
  @media screen and (min-width: 850px) {
    @content;
  }
}
@mixin only-tablet {
  @media screen and (min-width: 581px) and (max-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content;
  }
}
@mixin only-desktop {
  @media screen and (min-width: 1025px) {
    @content;
  }
}
@mixin hover {
  @media screen and (max-width: 580px) {
    &:active {
      @content;
    }
  }
  @media screen and (min-width: 581px) {
    &:hover {
      @content;
    }
  }
}
